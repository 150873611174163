:root[data-uinew] {
    --ui-font-family: var(--font-family-primary, 'Open Sans', sans-serif);
    --nui-loader-active-tick-opacity: 1;

    [data-uitheme="light"],
    &[data-uitheme="light"] {
        --nui-loader-active-tick: var(--fill-brand-primary-boldest);
    }

    [data-uitheme="dark"],
    &[data-uitheme="dark"] {
        --nui-loader-active-tick: var(--neutral-000);
    }
}
